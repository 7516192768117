/**
 * 工作流
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon,Tooltip,Message,Switch, Select,Modal, Option,Button,Tabs,TabPane,Dropdown,DropdownMenu,DropdownItem,Upload,Spin,Drawer,Table,Poptip,InputNumber,CheckboxGroup,Checkbox } from "view-design";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Tooltip', Tooltip);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Button', Button);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('onSwitch', Switch);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Upload', Upload);
Vue.component('Spin', Spin);
Vue.component('Modal', Modal);
Vue.component('Drawer', Drawer);
Vue.component('Table', Table);
Vue.component('Poptip', Poptip);
Vue.component('InputNumber', InputNumber);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('Checkbox', Checkbox);
import {
    configList,
    NODE_NAME,
    triggerList,
    TRIGGER_NAME,
    colorList,
    btnText,
    imageReveal,
    backgroundType,
    addWorkflowImage,
    batchColumns,
    batchInputColumns,
    tasksType,
    inputType,
    unitType,
    announcementsType,
    endSettingsType,
    DEFAULT_BUTTON_NAME,
    quickFeedbackArray,
    editWorkFlowService,
    getWorkFlowInfoService,
    updateWorkFlowStatusService,
    editTaskListService,
    getAllImageUrlService,
    getWorkFlowNodesService
} from "@/service/workflow-service";
import { Graph } from '@antv/x6'
import { startDragToGraph } from '@/utils/graph'
import { mapMutations, mapState } from "vuex";
import { validateCharNumber,onGuid } from '@/utils/tools'
import { onSysncUploadFiles } from "@/utils/request-function";
import { systemUploadType,queryFilesInfoService } from "@/service/system-service";

const ProcessConfig = {
    name:'ProcessConfig',
    computed: {
        ...mapState({
            flowId: state => state.system.flowId, //流程id
        }),
        /**
         * 触发条件选中效果
         * @returns {function(*, *): string}
         */
        onTriggerStyle(){
            return (value,triggerStatus) =>{
                let style = ''
                if(triggerStatus.indexOf(value) > -1){
                    style = 'color:#2d8cf0;background:#f1faff'
                }
                return style
            }
        },
        /**
         * 触发条件选中样式
         * @returns {function(*=, *): boolean}
         */
        onTriggerCheck(){
            return (value,triggerStatus) =>{
                let status = false
                if(triggerStatus.indexOf(value) > -1){
                    status = true
                }
                return status
            }
        }
    },
    data() {
        return {
            configLoading:false,
            systemLoading:false, //右侧属性设置loading
            taskModalVisible:false, //任务弹框可显示
            editLoading:false,
            addWorkflowImage:addWorkflowImage,
            configList:configList, //工作流菜单
            triggerList:triggerList, //触发数据
            tasksType:tasksType, //任务类型
            batchColumns:batchColumns, //批量操作显示数据
            batchInputColumns:batchInputColumns, //批量输入显示数据
            defaultButtonName:DEFAULT_BUTTON_NAME,
            triggerARStatus:false, //触发按钮控制状态
            batchLoading:false, //批量操作数据loading
            NODE_NAME:NODE_NAME, //节点code
            TRIGGER_NAME:TRIGGER_NAME, //触发字典名称
            colorList:colorList, //颜色列表
            btnText:btnText,// 按钮文字列表
            imageReveal:imageReveal,// 图片展示方式
            inputType:inputType, //输入类型
            announcementsType:announcementsType, //播报类型
            backgroundType:backgroundType, //背景设置类型
            endSettingsType:endSettingsType, //结束设置类型
            quickFeedbackArray:quickFeedbackArray, //快捷反馈数据列表
            unitType:unitType, //单位
            configNameVisible:false, //设置工作流边框可显示
            printVisible:true,
            lineVisible:false,
            startLineVisible:false,
            lineSelectList:[],
            sourceId:'', //来源节点的id
            lineId:'', //当前线条的id
            sourceBtnList:[], //来源的按钮组
            nodeArray:[],
            thumbVisible:false, //缩略图可显示
            batchVisible:false, //批量操作侧边栏可显示
            batchInputVisible:false, //批量输入侧边栏可显示
            lookImageVisible:false, //查看图片
            lookARVisible:false, //AR端显示
            lookARBatchInputVisible:false, //AR端显示批量输入
            lookARObj:{}, //批量列表单条数据源
            nodeObj:{}, //节点内容
            flowReturnObj:{}, //保存流程数据源
            imageObj:{}, //图片参数对象
            drawing:false, //是否绘制
            imageWidth:0,
            imageHeight:0,
            imageLeft:0,
            imageTop:0,
            currentX:0, //记录落点的x坐标
            currentY:0, //记录落点的Y坐标
            offsetWidth:0, //记录滚动内容宽度
            imageRatio:100,
            graph:null,
            shape:null,
            addon:null,
            zoom:1,
            saveStatus:false,
            btnList:{
                colorId:1,
                color:'#eccf3e',
                buttonText:'合格',
                buttonId:1,
                triggerStatus:[],
                buttonCode:'',
            },
            configParams:{
                tasksName:'', //任务名称
                configName:'', //设置工作流名称
                tasksType:'', //任务类型
                videotapeStatus:1, //全程录像设置
                smallWindowPreview:0, //是否小窗口预览
                quickFeedback:1, //快捷反馈
                quickFeedbackList:['photograph','soundRecording'], //快捷反馈内容
                flowName:'',
                inspection_annex:'',
                annexList:[],
            },
        }
    },
    created() {
        this.onWorkFlowNodesService().then()
        if(this.flowId !== ''){
            this.onWorkFlowInfo().then()
        }
    },
    mounted() {
        this.onInit();
    },
    methods: {
        ...mapMutations('system',['SET_FLOW_ID','SET_TASK_EDIT']),
        /**
         * 初始化流程图
         */
        onInit() {
            const container = document.getElementById('container')
            const graph = new Graph({
                grid: {
                    size:10,
                    visible:true,
                    type:'mesh',
                    args:{
                        color:'#e9ebee',
                        thickness:1,
                        factor:10
                    },
                },
                selecting: {
                    enabled: true,
                    // rubberband: true,
                    className: 'container-selecting',
                    showNodeSelectionBox: true,
                    showEdgeSelectionBox:true,
                },
                snapline: true,
                keyboard: true,
                clipboard: true,
                container:container,
                autoResize:true,
                scroller:{
                    enabled:true,
                    pannable:true
                },
                connecting: {
                    snap: true,     //当 snap 设置为 true 时连线的过程中距离节点或者连接桩 50px 时会触发自动吸附
                    allowBlank: false,      //是否允许连接到画布空白位置的点
                    allowLoop: false,       //是否允许创建循环连线，即边的起始节点和终止节点为同一节点
                    allowMulti:false,
                    highlight: true,
                    // sourceAnchor: 'center',     //当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
                    // targetAnchor: 'center',     //当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
                    // connectionPoint: 'anchor',
                    // 设置连接线的样式
                    router: {
                        // 样式名
                        name: 'manhattan',
                        args: {
                            startDirections: ['top', 'right', 'bottom', 'left'],    //支持从哪些方向开始路由。
                            endDirections: ['top', 'right', 'bottom', 'left'],      //支持从哪些方向结束路由。
                        },
                    },
                    // 连接线
                    createEdge(obj){
                        console.log(obj.sourceCell)
                        return graph.createEdge({
                            attribute:{
                                selectedId:'', //选中的id
                            },
                            attrs: {
                                line: {
                                    // stroke: '#2d8cf0',
                                    stroke: '#3e4c65',
                                    strokeWidth: 2,
                                    targetMarker: {
                                        name: 'block',
                                        args: {
                                            size: '6',
                                        },
                                    },
                                },
                                selecting: {
                                    enabled: true,
                                    rubberband: true,
                                    showNodeSelectionBox: true,
                                },
                            },
                        })
                    },
                    // 验证连接
                    validateConnection({
                                           targetCell,
                                           targetView,
                                           sourceMagnet,
                                           targetMagnet,
                                       }) {
                        // 只能从输出链接桩创建连接
                        if (!sourceMagnet) {
                            return false
                        }
                        // 只能连接到输入链接桩
                        if (!targetMagnet) {
                            return false
                        }
                        if(targetCell.store.data.nodeCode === NODE_NAME.NODE_START){
                            return false
                        }
                        // 判断目标链接桩是否可连接
                        const portId = targetMagnet.getAttribute('port');
                        const node = targetView.cell
                        const port = node.getPort(portId)
                        if (port && port.connected) {
                            return false
                        }
                        return true
                    },
                    validateEdge({edge}){
                        let edgesLength = graph.getConnectedEdges(edge.store.data.source.cell,{ outgoing: true })
                        let sourceData = graph.getCellById(edge.store.data.source.cell).store.data
                        if(sourceData.nodeCode === NODE_NAME.NODE_START || sourceData.nodeCode === NODE_NAME.NODE_PHOTO || sourceData.nodeCode === NODE_NAME.NODE_VOICE_INPUT || sourceData.nodeCode === NODE_NAME.NODE_BATCH_OPERATION || sourceData.nodeCode === NODE_NAME.NODE_BATCH_INPUT){
                            if(edgesLength.length>1){
                                Message.warning('该模块最多只能拉出1条线！')
                                return false
                            }
                        }
                        if(sourceData.nodeCode === NODE_NAME.NODE_END && edgesLength.length>0){
                            return false
                        }
                        let regularNumber = 0
                        for(let i in sourceData.attribute.nodeBtnList){
                            if(!sourceData.attribute.nodeBtnList[i].regularButton){
                                regularNumber+=1
                            }
                        }
                        if(edgesLength.length>regularNumber && sourceData.nodeCode !== NODE_NAME.NODE_START){
                            let resultRegular = sourceData.attribute.nodeBtnList.some(item =>{
                                if(item.regularButton){
                                    return true
                                }
                            })
                            Message.warning('该模块最多只能拉出'+(resultRegular?regularNumber:sourceData.attribute.nodeBtnList.length)+'条线！')
                            return false
                        }

                        let sourceBtnStatus
                        for(let i in sourceData.attribute.nodeBtnList){
                            if(sourceData.attribute.nodeBtnList[i].lineId === ''){
                                sourceBtnStatus = true
                            }
                        }
                        if(!sourceBtnStatus && sourceData.nodeCode !== NODE_NAME.NODE_START){
                            Message.warning('按钮已被全部关联，无法在拉取线条！')
                            return false
                        }
                        return true
                    }
                }
            });
            this.graph = graph
            //delete
            graph.bindKey('backspace', () => {
                if(this.flowReturnObj.status === 1){ return }
                const cells = graph.getSelectedCells()
                if (cells.length) {
                    const Id = cells[0].id
                    const cell = graph.getCellById(Id)
                    if(cell.isEdge()){
                        let btnList = this.graph.getCellById(this.sourceId).store.data.attribute.nodeBtnList
                        for(let i in btnList){
                            if(this.lineId === btnList[i].lineId){
                                btnList[i].lineId = ''
                            }
                        }
                        this.lineVisible = false
                        this.printVisible = true
                        this.startLineVisible = false
                        graph.removeCells(cells)
                        return
                    }
                    Modal.confirm({
                        title: '确认要删除该步骤吗?',
                        content: '删除后不可回退，配置的信息将会丢失。',
                        okText:'确定',
                        cancelText:'取消',
                        onOk:()=>{
                            let incomingLength = this.graph.getConnectedEdges(Id,{ incoming: true }) //返回输入的边
                            for(let i in incomingLength){
                                let btnList = this.graph.getCellById(incomingLength[i].source.cell).store.data.attribute.nodeBtnList
                                for(let j in btnList){
                                    if(incomingLength[i].id === btnList[j].lineId){
                                        btnList[j].lineId = ''
                                    }
                                }
                            }
                            graph.removeCells(cells)
                            this.printVisible = true
                            this.thumbVisible = false
                            this.nodeObj = {}
                        },
                    });
                }
            })
            graph.bindKey('delete',() =>{
                if(this.flowReturnObj.status === 1){ return }
                const cells = graph.getSelectedCells()
                if (cells.length) {
                    const Id = cells[0].id
                    const cell = graph.getCellById(Id)
                    if(cell.isEdge()){
                        let btnList = this.graph.getCellById(this.sourceId).store.data.attribute.nodeBtnList
                        for(let i in btnList){
                            if(this.lineId === btnList[i].lineId){
                                btnList[i].lineId = ''
                            }
                        }
                        this.$forceUpdate()
                        this.lineVisible = false
                        this.printVisible = true
                        this.startLineVisible = false
                        graph.removeCells(cells)
                        return
                    }
                    Modal.confirm({
                        title: '确认要删除该步骤吗?',
                        content: '删除后不可回退，配置的信息将会丢失。',
                        okText:'确定',
                        cancelText:'取消',
                        onOk:()=>{
                            let incomingLength = this.graph.getConnectedEdges(Id,{ incoming: true }) //返回输入的边
                            for(let i in incomingLength){
                                let btnList = this.graph.getCellById(incomingLength[i].source.cell).store.data.attribute.nodeBtnList
                                for(let j in btnList){
                                    if(incomingLength[i].id === btnList[j].lineId){
                                        btnList[j].lineId = ''
                                    }
                                }
                            }
                            graph.removeCells(cells)
                            this.printVisible = true
                            this.thumbVisible = false
                            this.nodeObj = {}
                        },
                    });
                }
            })
            /**
             * 节点被添加到画布中触发
             */
            graph.on('node:added',(node) =>{
                graph.resetSelection(node.cell)
            })
            /**
             * 监听连线完成后获取线条数据
             */
            graph.on('edge:connected', ({ isNew, edge }) => {
                if (isNew) {
                    graph.resetSelection(edge)
                }
            })
            /**
             * 节点被选中时触发
             */
            graph.on('node:selected', (node) => {
                this.onAddNodeData(node)
                console.log(node)
            })
            /**
             * 连接线被选中时触发
             */
            graph.on('edge:selected',(cell) =>{
                if(!cell.cell.target.cell){
                    return
                }
                cell.cell.toFront()
                this.lineSelectList = []
                let cellObj = cell.cell.store.data
                this.sourceId = cellObj.source.cell
                this.lineId = cell.cell.id
                let data = this.graph.getCellById(this.sourceId).store.data
                this.sourceBtnList = data.attribute.nodeBtnList
                if(data.id === cellObj.source.cell){
                    if(data.nodeCode === NODE_NAME.NODE_START){
                        this.startLineVisible = true
                        this.lineVisible = false
                    }else{
                        this.startLineVisible = false
                        this.lineVisible = true
                    }
                }
                let regularBtnCount = 0
                for(let i in this.sourceBtnList){
                    if(!this.sourceBtnList[i].regularButton){
                        regularBtnCount+=1
                    }
                }
                if(regularBtnCount === 1){
                    for(let i in this.sourceBtnList){
                        if(!this.sourceBtnList[i].regularButton){
                            this.sourceBtnList[i].lineId = this.lineId
                        }
                    }
                }
                for(let i in this.sourceBtnList){
                    if(this.sourceBtnList[i].lineId !==''){
                        this.$nextTick(()=>{
                            this.lineSelectList.push(this.sourceBtnList[i].id)
                        })
                    }
                }

                this.nodeObj = {}
                this.printVisible = false
            })
            /**
             * 点击空白处
             */
            graph.on('blank:click',()=>{
                this.nodeObj = {}
                this.lineVisible = false
                this.printVisible = true
                this.startLineVisible = false
            })
            graph.on('node:moving',()=>{
                this.onValidateData()
            })
            graph.on('edge:moved',()=>{
                this.onValidateData()
            })
            graph.on('cell:removed', () => {
                this.onValidateData()
            })
            graph.on('cell:added', () => {
                this.onValidateData()
            })
        },
        /**
         * 创建节点数据
         * @param node
         */
        onAddNodeData(node){
            this.nodeObj = {}
            this.printVisible = false
            this.lineVisible = false
            this.nodeObj = node.node.store.data
            this.nodeObj.nodeName = node.node.store.data.attrs.label.text
            this.thumbVisible = true
            this.triggerARStatus = false
            this.startLineVisible = false

            let resultRegular = this.nodeObj.attribute.nodeBtnList.some(item =>{
                if(!item.regularButton){
                    return true
                }
            })
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_END){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#484c52',
                      buttonText:'取消',
                      buttonCode:DEFAULT_BUTTON_NAME.CANCELLATION,
                      defaultStatus:false,
                      triggerStatus:[],
                      regularButton:true,
                  },
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'确定',
                      buttonCode:DEFAULT_BUTTON_NAME.CONFIRM,
                      defaultStatus:false,
                      triggerStatus:[],
                      regularButton:false,
                  },
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_PHOTO){
                this.nodeObj.attribute.nodeBtnList.push(
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#2d8cf0',
                        buttonText:'拍照',
                        buttonCode:DEFAULT_BUTTON_NAME.TRIGGER_PHOTO,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#2d8cf0',
                        buttonText:'重拍一次',
                        buttonCode:DEFAULT_BUTTON_NAME.TAKE_NEW_PHOTO,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#484c52',
                        buttonText:'下一步',
                        buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:false,
                    }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT){
                this.nodeObj.attribute.nodeBtnList.push(
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#2d8cf0',
                        buttonText:'开始录音',
                        buttonCode:DEFAULT_BUTTON_NAME.START_RECORDING,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#2d8cf0',
                        buttonText:'重录一次',
                        buttonCode:DEFAULT_BUTTON_NAME.RECORDING_AGAIN,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#484c52',
                        buttonText:'播放',
                        buttonCode:DEFAULT_BUTTON_NAME.PLAY_RECORDING,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#484c52',
                        buttonText:'停止播放',
                        buttonCode:DEFAULT_BUTTON_NAME.STOP_PLAY_RECORDING,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:true,
                    },
                    {
                        id:onGuid(),
                        lineId:'',
                        trueValue:0,
                        color:'#484c52',
                        buttonText:'下一步',
                        buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                        defaultStatus:false,
                        triggerStatus:[],
                        regularButton:false,
                    }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_AI_IDENTIFY){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:true,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_ENVIRONMENTAL_MONITORING){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:true,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:true,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_IMAGE){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      color:'#57c22d',
                      buttonText:'查看图片',
                      buttonCode:DEFAULT_BUTTON_NAME.LOOK_IMAGE,
                      defaultStatus:false,
                      buttonLookImageStatus:0,
                      triggerStatus:[],
                      regularButton:true,
                  },
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:true,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_TEXT){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:true,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_BATCH_INPUT){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:false,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(!resultRegular && this.nodeObj.nodeCode === NODE_NAME.NODE_BATCH_OPERATION){
                this.nodeObj.attribute.nodeBtnList.push(
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'选择默认',
                      buttonCode:DEFAULT_BUTTON_NAME.SELECTED_DEFAULT,
                      defaultStatus:false,
                      triggerStatus:[],
                      regularButton:true,
                  },
                  {
                      id:onGuid(),
                      lineId:'',
                      trueValue:0,
                      color:'#2d8cf0',
                      buttonText:'下一步',
                      buttonCode:DEFAULT_BUTTON_NAME.NEXT_STEP,
                      defaultStatus:false,
                      triggerStatus:[],
                      regularButton:false,
                  }
                )
            }
            if(this.nodeObj.nodeCode === NODE_NAME.NODE_IMAGE){
                if(this.nodeObj.attribute.imageId!=''){
                    this.onImageUrl(this.nodeObj.attribute.imageId).then()
                }
                for(let i in this.nodeObj.attribute.nodeBtnList){
                    if(this.nodeObj.attribute.nodeBtnList[i].buttonCode === DEFAULT_BUTTON_NAME.LOOK_IMAGE){
                        if(this.nodeObj.attribute.nodeBtnList[i].buttonLookImageStatus === undefined || this.nodeObj.attribute.nodeBtnList[i].buttonLookImageStatus === null){
                            this.nodeObj.attribute.nodeBtnList[i].buttonLookImageStatus = 1
                        }
                    }
                }
            }
            /**
             * 语音输入节点
             */
            if(this.nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT){
                if(this.nodeObj.attribute.inputTypeCode === ''){
                    this.nodeObj.attribute.inputTypeName = '文本'
                    this.nodeObj.attribute.inputTypeCode = 'text'
                }
                if(this.nodeObj.attribute.inputTypeCode === 'text'){
                    this.nodeObj.attribute.unitType = ''
                }
                if(this.nodeObj.attribute.timedRecordingStatus === undefined || this.nodeObj.attribute.timedRecordingStatus === null){
                    this.nodeObj.attribute.timedRecordingStatus = 0
                }
                if(this.nodeObj.attribute.timedRecordingNumber === undefined || this.nodeObj.attribute.timedRecordingNumber === '' ||  this.nodeObj.attribute.timedRecordingNumber === null){
                    this.nodeObj.attribute.timedRecordingNumber = 5
                }
            }
            /**
             * 拍照节点
             */
            if(this.nodeObj.nodeCode === NODE_NAME.NODE_PHOTO){
                if(this.nodeObj.attribute.cameraTimerStatus === undefined || this.nodeObj.attribute.cameraTimerStatus === null){
                    this.nodeObj.attribute.cameraTimerStatus = 0
                }
                if(this.nodeObj.attribute.cameraTimerNumber === undefined || this.nodeObj.attribute.cameraTimerNumber === '' ||  this.nodeObj.attribute.cameraTimerNumber === null){
                    this.nodeObj.attribute.cameraTimerNumber = 5
                }
            }
            if(this.nodeObj.attribute.endSettingsType === '' || this.nodeObj.attribute.endSettingsType === undefined){
                this.nodeObj.attribute.endSettingsText = '弹窗确认'
                this.nodeObj.attribute.endSettingsType = 'popupConfirmation'
            }
            if(this.nodeObj.attribute.announcementsType === '' || this.nodeObj.attribute.announcementsType === undefined){
                this.nodeObj.attribute.announcementsText = '步骤名称'
                this.nodeObj.attribute.announcementsType = 'nodeName'
            }
            if(this.nodeObj.attribute.timedJumpStatus === undefined || this.nodeObj.attribute.timedJumpStatus === null){
                this.nodeObj.attribute.timedJumpStatus = 0
            }
            if(this.nodeObj.attribute.timedJumpNumber === undefined || this.nodeObj.attribute.timedJumpNumber === '' ||  this.nodeObj.attribute.timedJumpNumber === null){
                this.nodeObj.attribute.timedJumpNumber = 5
            }
            /**
             * 设备状态节点
             */
            if(this.nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS || this.nodeObj.nodeCode === NODE_NAME.NODE_TEXT || this.nodeObj.nodeCode === NODE_NAME.NODE_IMAGE){
                if(this.nodeObj.attribute.backgroundCode === ''){
                    this.nodeObj.attribute.backgroundText = '默认'
                    this.nodeObj.attribute.backgroundCode = 'default'
                }
            }
            if(this.nodeObj.attribute.annexList === undefined){
                this.nodeObj.attribute.annexList = []
            }
            this.$forceUpdate()
        },
        /**
         * 选择线条触发条件
         * @param list
         */
        onLineCheckBox(list){
            for(let i in this.sourceBtnList){
                if(this.sourceBtnList[i].lineId  === '' && this.sourceBtnList[i].id === list.target._value){
                    this.sourceBtnList[i].lineId = this.lineId
                    this.lineSelectList.push( this.sourceBtnList[i].id)
                }else if(this.sourceBtnList[i].lineId!=='' && this.sourceBtnList[i].id === list.target._value){
                    this.sourceBtnList[i].lineId = ''
                }
            }
            for(let i in this.lineSelectList){
                if(list.target._value === this.lineSelectList[i]){
                    this.lineSelectList.splice(i,1)
                }
            }
            let resultLineId = this.sourceBtnList.some(item =>{
                if(item.lineId !== this.lineId){
                    return true
                }
            })
            let outgoingLength = this.graph.getConnectedEdges(this.sourceId,{ outgoing: true })
            if(!resultLineId){
                for(let i in outgoingLength){
                    if(outgoingLength[i].id !== this.lineId){
                        this.graph.removeCell(outgoingLength[i].id)
                    }
                }
            }
            this.onValidateData();
        },
        /**
         * 开启全局录像
         */
        onVideoChange(){
            this.configParams.videotapeStatus = 1
            this.onValidateData();
        },
        /**
         * 开启小窗口预览
         */
        onSmallWindowPreview(){
            this.configParams.smallWindowPreview = 1
            this.onValidateData();
        },
        onQuickFeedback(status) {
            if(status === 1){
                this.configParams.quickFeedbackList = ['photograph','soundRecording']
            }
        },
        /**
         * 返回
         */
        onBack() {
            console.log(this.flowReturnObj)
            // // console.log()
            if(JSON.stringify(this.flowReturnObj)!=='{}' && this.flowReturnObj.status !==1 && JSON.stringify(JSON.parse(this.flowReturnObj.detail).cells) != JSON.stringify(this.graph.toJSON().cells)){
                Modal.confirm({
                    title: '您正在编辑工作流，确定要返回吗?',
                    content: '返回后不可回退，配置的信息将会丢失。',
                    okText:'确定',
                    cancelText:'取消',
                    onOk:()=>{
                        this.$router.push('/workflow/process/list')
                    },
                });
                return
            }
            this.$router.push('/workflow/process/list')
        },
        /**
         * 验证数据
         */
        onValidateData(){
            if(JSON.stringify(this.flowReturnObj)!=='{}' && JSON.stringify(JSON.parse(this.flowReturnObj.detail).cells) != JSON.stringify(this.graph.toJSON().cells)){
                this.saveStatus = true
            }else if(JSON.stringify(this.flowReturnObj)==='{}'){
                this.saveStatus = true
            }else{
                this.saveStatus = false
            }
        },
        onValidateName(){
            console.log(this.configParams.flowName)
            console.log(this.configParams.configName)
            if(this.configParams.flowName === this.configParams.configName && JSON.stringify(this.flowReturnObj)!=='{}'){
                this.saveStatus = false
            }else if(JSON.stringify(this.flowReturnObj)==='{}'){
                this.saveStatus = true
            }else{
                this.saveStatus = true
            }
        },
        /**
         * 放大画布
         */
        onThumbEnlarge() {
            if(this.zoom > 1.5){
                Message.warning('画布已经放大到最大值！')
                return
            }
            this.zoom += 0.1
            this.graph.zoomTo(this.zoom)
        },
        /**
         * 缩小画布
         */
        onThumbNarrow() {
            if(this.zoom < 0.6){
                Message.warning('画布已经缩放到最小值！')
                return
            }
            this.zoom -= 0.1
            this.graph.zoomTo(this.zoom)
        },
        /**
         * 删除节点
         */
        onClearNode(){
            Modal.confirm({
                title: '确认要删除该步骤吗?',
                content: '删除后不可回退，配置的信息将会丢失。',
                okText:'确定',
                cancelText:'取消',
                onOk:()=>{
                    let incomingLength = this.graph.getConnectedEdges(this.nodeObj.id,{ incoming: true }) //返回输入的边
                    for(let i in incomingLength){
                        let btnList = this.graph.getCellById(incomingLength[i].source.cell).store.data.attribute.nodeBtnList
                        for(let j in btnList){
                            if(incomingLength[i].id === btnList[j].lineId){
                                btnList[j].lineId = ''
                            }
                        }
                    }
                    this.graph.removeCell(this.nodeObj.id)
                    this.printVisible = true
                    this.thumbVisible = false
                    this.nodeObj = {}
                },
            });
        },
        /**
         * 生成节点
         * @param type
         * @param e
         * @param code
         */
        startDrag(type, e,code) {
            startDragToGraph(this.graph, type, e,code);
        },
        /**
         * 节点开始结束判断
         * @param name
         * @returns {*}
         */
        onNodeStartEndCheck(name) {
            return this.graph.toJSON().cells.some(function(item) {
                 if (item.nodeCode === name) {
                     return true;
                 }else{
                     return false
                 }
            })
        },
        /**
         * 发布
         */
        onRelease(status) {
            if(JSON.stringify(this.flowReturnObj)=='{}' || this.saveStatus){
                Message.warning('请先保存工作流！')
                return
            }
            this.onUpdateWorkFlowStatus(status).then()
        },
        /**
         * 提交验证
         */
        onSubmitCheck(status) {
            if(this.configParams.tasksName === ''){
                Message.error('请填写任务名称！');
                return;
            }
            if(this.configParams.tasksType === '' || !this.configParams.tasksType){
                Message.error('请选择任务类型！');
                return;
            }
            this.onEditTaskList(status).then()
        },
        /**
         * 点击上传
         */
        choiceImg(){
            this.$refs.inputer.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 上传附件
         */
        choiceFile(){
            if(this.configParams.annexList.length>=4){
                Message.warning('附件最多上传4个！')
                return
            }
            this.$refs.inputerFile.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 节点上传附件
         */
        choiceNodeFile(){
            if(this.nodeObj.attribute.annexList.length>=4){
                Message.warning('附件最多上传4个！')
                return
            }
            this.$refs.inputerNodeFile.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 点击批量上传
         * @param key
         */
        choiceBatchImg(key){
            this.$refs[`batchInputer${key}`].dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 清空全局附件
         * @param key
         */
        onAnnexClear(key){
            this.configParams.annexList.splice(key,1)
        },
        /**
         * 清空节点附件
         * @param key
         */
        onAnnexNodeClear(key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.annexList.splice(key,1)
            this.$forceUpdate()
            this.onValidateData()
        },
        /**
         * 验证图片格式/拉取参数
         * @param e
         * @returns {Promise<void>}
         */
        async onSignUpLoad(e) {
            let files = e.target.files[0]
            this.$refs.inputer.value = ''
            if(files){
                if(files.type !== 'image/jpeg' && files.type !== 'image/png' && files.type !== 'image/gif'){
                    Message.warning('文件格式错误，只能上传jpg/png/gif文件！')
                    return;
                }
                if(files.size > 3*1024*1024){
                    Message.warning('只能上传3M以内的图片！')
                    return;
                }
                this.configLoading = true
                onSysncUploadFiles([files],systemUploadType.inspection_node_image).then((data)=>{
                    const cells = this.graph.getSelectedCells()
                    cells[0].store.data.attribute.imageId = data[0].resourceName
                    cells[0].store.data.attribute.imageUrl = data[0].url
                    this.configLoading = false
                }).catch(()=>{
                    this.configLoading = false
                    Message.error('上传失败，请重新尝试！')
                })
                this.onValidateData()
            }
        },
        /**
         * 验证文件格式/拉取参数
         * @param e
         * @returns {Promise<void>}
         */
        async onFileUpLoad(e,status) {
            let files = e.target.files[0]
            if(status === 'node'){
                this.$refs.inputerNodeFile.value = ''
            }else{
                this.$refs.inputerFile.value = ''
            }
            if(files){
                this.configLoading = true
                console.log(files)
                onSysncUploadFiles([files],systemUploadType.inspection_annex).then((data)=>{
                    if(status === 'node'){
                        const cells = this.graph.getSelectedCells()
                        cells[0].store.data.attribute.annexList.push({
                            annexPath:data[0].resourceName,
                            annexName:data[0].fileName
                        })
                    }else{
                        this.configParams.annexList.push({
                            annexPath:data[0].resourceName,
                            annexName:data[0].fileName
                        })
                    }
                    //replace(/\.[^/.]+$/, "");
                    this.configLoading = false
                }).catch(()=>{
                    this.configLoading = false
                    Message.error('上传失败，请重新尝试！')
                })
                this.onValidateData()
            }
        },
        /**
         * 验证图片格式
         * @param e
         * @param key
         * @returns {Promise<void>}
         */
        async onBatchUpLoad(e,key) {
            let files = e.target.files[0]
            this.$refs[`batchInputer${key}`].value = ''
            if(files){
                if(files.type !== 'image/jpeg' && files.type !== 'image/png' && files.type !== 'image/gif'){
                    Message.warning('文件格式错误，只能上传jpg/png/gif文件！')
                    return;
                }
                if(files.size > 3*1024*1024){
                    Message.warning('只能上传3M以内的图片！')
                    return;
                }
                this.batchLoading = true
                onSysncUploadFiles([files],systemUploadType.inspection_node_image).then((data)=>{
                    const cells = this.graph.getSelectedCells()
                    cells[0].store.data.attribute.batchList[key].imageId = data[0].resourceName
                    cells[0].store.data.attribute.batchList[key].imageUrl = data[0].url
                    this.batchLoading = false
                }).catch(()=>{
                    this.batchLoading = false
                    Message.error('上传失败，请重新尝试！')
                })
            }
        },
        /**
         * 节点验证
         * @param obj
         */
        onNodeVisible(obj,code){
             return obj.filter((e)=>e.nodeCode === code).length
        },
        /**
         * 节点必填项验证
         */
        onNodeCheck(data) {
            let obj = {}
            for(let i in data){
                if(data[i].nodeCode === NODE_NAME.NODE_AI_IDENTIFY){
                    if(data[i].attribute.modelName === ''){
                        obj.name = 'Model'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_DEVICE_STATUS){
                    if(data[i].attribute.deviceName === ''){
                        obj.name = 'Device'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_TEXT){
                    if(data[i].attribute.nodeContent === ''){
                        obj.name = 'Text'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_IMAGE){
                    if(data[i].attribute.imageUrl === ''){
                        obj.name  = 'Image'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                    if(data[i].attribute.nodeContent === ''){
                        obj.name  = 'ImageContent'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_BATCH_OPERATION){
                    if(data[i].attribute.batchList.length === 0){
                        obj.name  = 'Batch'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_BATCH_INPUT){
                    if(data[i].attribute.batchInputList.length === 0){
                        obj.name  = 'BatchInput'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_VOICE_INPUT){
                    if(data[i].attribute.unitType === '' && data[i].attribute.inputTypeCode === 'number'){
                        obj.name = 'unit'
                        obj.number = data[i].nodeNumber || '-'
                        obj.id = data[i].id
                    }
                }
            }
            return obj
        },
        /**
         * 节点按钮验证
         * @param data
         */
        onNodeButtonCheck(data){
            let obj = {}
            for(let i in data){
                if(data[i].attribute){
                    for(let j in data[i].attribute.nodeBtnList){
                        if(data[i].attribute.nodeBtnList[j].buttonText === ''){
                            obj.status = true
                            obj.number = data[i].nodeNumber || '-'
                        }
                    }
                }
            }
            return obj
        },
        /**
         * 批量配置按钮验证
         * @param data
         */
        onBatchButtonCheck(data){
            let obj = {}
            for(let i in data){
                for(let j in data[i].btnList){
                    if(data[i].btnList[j].buttonText === ''){
                        obj.status = true
                        obj.number = Number(i)+1
                    }
                }
            }
            return obj
        },
        /**
         * 批量输入语音验证
         * @param data
         */
        onBatchInputVoiceCheck(data){
            let obj = {}
            for(let i in data){
                if(data[i].unitType === '' && data[i].inputTypeCode === 'number'){
                    obj.status = true
                    obj.number = Number(i)+1
                }
            }
            return obj
        },
        /**
         * 批量配置按钮点击验证
         * @param data
         */
        onBatchButtonClickCheck(data){
            let status = false
            for(let i in data){
                if(data[i].buttonText === ''){
                    status = true
                }
            }
            return status
        },
        /**
         * 保存导出json
         */
        onSave() {
            if(!this.saveStatus){
                return
            }
            if(this.configParams.configName === ''){
                Message.warning('请先填写工作流名称！')
                return;
            }

            if(this.graph.toJSON().cells.length === 0){
                Message.warning('请配置工作流！')
                return;
            }

            if(!this.onNodeStartEndCheck(NODE_NAME.NODE_START) || !this.onNodeStartEndCheck(NODE_NAME.NODE_END)){
                Message.warning({content:'一个工作流必须要有一个开始和一个结束步骤！',duration:3})
                return;
            }

            let obj = []
            for(let i in this.graph.toJSON().cells){
                if(this.graph.toJSON().cells[i].shape === 'rect'){
                    obj.push(this.graph.toJSON().cells[i])
                }
            }
            if(this.onNodeVisible(obj,NODE_NAME.NODE_START) >1 || this.onNodeVisible(obj,NODE_NAME.NODE_END)>1){
                Message.warning({content:'工作流只能有一个开始和一个结束步骤！',duration:3})
                return
            }

            if(this.graph.getNodes().length <3){
                Message.warning({content:'一个工作流必须要有至少一个UI控制步骤！',duration:3})
                return
            }

            for(let i in this.graph.getNodes()){
                let outgoingLength = this.graph.getConnectedEdges(this.graph.getNodes()[i].id,{ outgoing: true }) //返回输出的边
                let incomingLength = this.graph.getConnectedEdges(this.graph.getNodes()[i].id,{ incoming: true }) //返回输入的边
                if(this.graph.getNodes()[i].store.data.nodeCode === NODE_NAME.NODE_START){
                    if(outgoingLength.length === 0){
                        Message.warning({content:'请检查开始步骤的连线配置！',duration:3})
                        return
                    }
                }
                if(this.graph.getNodes()[i].store.data.nodeCode === NODE_NAME.NODE_END){
                    if(incomingLength.length === 0){
                        Message.warning({content:'请检查结束步骤的连线配置！',duration:3})
                        return
                    }
                }
                if(this.graph.getNodes()[i].store.data.nodeCode !== NODE_NAME.NODE_START && this.graph.getNodes()[i].store.data.nodeCode !== NODE_NAME.NODE_END){

                    if(outgoingLength.length === 0){
                        Message.warning({content:'编号为'+this.graph.getNodes()[i].store.data.nodeNumber+'的步骤没有指出线条！',duration:3})
                        this.graph.resetSelection(this.graph.getNodes()[i].store.data.id)
                        return
                    }
                    if(incomingLength.length === 0){
                        Message.warning({content:'编号为'+this.graph.getNodes()[i].store.data.nodeNumber+'的步骤没有指入线条！',duration:3})
                        this.graph.resetSelection(this.graph.getNodes()[i].store.data.id)
                        return
                    }
                    let nodeButtonName = ''
                    let resultLineId = this.graph.getNodes()[i].store.data.attribute.nodeBtnList.some(item =>{
                        if(item.lineId === '' && !item.regularButton){
                            return true
                        }
                    })
                    for(let j in this.graph.getNodes()[i].store.data.attribute.nodeBtnList){
                        if(this.graph.getNodes()[i].store.data.attribute.nodeBtnList[j].lineId === '' && !this.graph.getNodes()[i].store.data.attribute.nodeBtnList[j].regularButton){
                            nodeButtonName +=this.graph.getNodes()[i].store.data.attribute.nodeBtnList[j].buttonText+'、'
                        }
                    }
                    if(resultLineId){
                        nodeButtonName = nodeButtonName.substring(0,nodeButtonName.lastIndexOf('、'))
                        Message.warning({
                            content:'编号为'+this.graph.getNodes()[i].store.data.nodeNumber+'的步骤的'+nodeButtonName+'按钮缺少线条关联！',
                            duration: 3
                        })
                        this.graph.resetSelection(this.graph.getNodes()[i].store.data.id)
                        return
                    }
                }
            }

            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'Text'){
                Message.warning({content:'编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤未填写作业内容！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }

            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'Model'){
                Message.warning({content:'编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤未填写模型名称！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'Device'){
                Message.warning({content:'编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤未填写设备名称！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }

            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'Image'){
                Message.warning({content:'编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤未上传图片！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'ImageContent'){
                Message.warning({content:'编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤未填写作业内容！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'Batch'){
                Message.warning({content:'请为编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的批量操作步骤配置数据！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'BatchInput'){
                Message.warning({content:'请为编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的批量输入步骤配置数据！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeCheck(this.graph.toJSON().cells).name === 'unit'){
                Message.warning({content:'请为编号为'+this.onNodeCheck(this.graph.toJSON().cells).number+'的步骤添加单位！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.onNodeButtonCheck(this.graph.toJSON().cells).status){
                Message.warning({content:'编号为'+this.onNodeButtonCheck(this.graph.toJSON().cells).number+'的步骤未添加按钮文字！',duration: 3})
                this.graph.resetSelection(this.onNodeCheck(this.graph.toJSON().cells).id)
                return
            }
            if(this.configParams.quickFeedback === 1 && this.configParams.quickFeedbackList.length === 0){
                Message.warning({
                    content:'全局配置里快捷操作未选中内容！',
                    duration: 3
                })
                return;
            }
            this.onEditWorkFlow(this.graph.toJSON()).then()

        },
        /**
         * 节点默认回显
         * @param text
         */
        onNodeName(text){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attrs.label.text = text.target._value
            cells[0].attr('text/text', text.target._value)
            if(cells[0].store.data.attrs.label.text === ''){
                switch (this.nodeObj.nodeCode){
                    case NODE_NAME.NODE_TEXT:
                        cells[0].store.data.attrs.label.text = '文本'
                        cells[0].attr('text/text', '文本')
                    break;
                    case NODE_NAME.NODE_PHOTO:
                        cells[0].store.data.attrs.label.text = '拍照'
                        cells[0].attr('text/text', '拍照')
                        break;
                    case NODE_NAME.NODE_VOICE_INPUT:
                        cells[0].store.data.attrs.label.text = '语音输入'
                        cells[0].attr('text/text', '语音输入')
                        break;
                    case NODE_NAME.NODE_IMAGE:
                        cells[0].store.data.attrs.label.text = '图片'
                        cells[0].attr('text/text', '图片')
                        break;
                    case NODE_NAME.NODE_BATCH_OPERATION:
                        cells[0].store.data.attrs.label.text = '批量操作'
                        cells[0].attr('text/text', '批量操作')
                        break;
                    case NODE_NAME.NODE_BATCH_INPUT:
                        cells[0].store.data.attrs.label.text = '批量输入'
                        cells[0].attr('text/text', '批量输入')
                        break;
                }
            }
            this.onValidateData()
        },
        onNodeTitle(title) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeTitle = title.target._value
        },
        onNodeContent(content) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeContent = content.target._value.replace(/↵/g,"\n")
            console.log(content.target._value.replace(/↵/g,"\n"))
            this.onValidateData()
        },
        onAnnouncementsContent(content) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.customText = content.target._value.replace(/↵/g,"\n")
            this.onValidateData()
        },
        onModelContent(content) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.modelName = content.target._value.replace(/↵/g,"\n")
            this.onValidateData()
        },
        onDeviceContent(content) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.deviceName = content.target._value.replace(/↵/g,"\n")
            this.onValidateData()
        },
        /**
         * 给按钮添加disabled
         */
        onButtonDisabled() {
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.nodeBtnList
            for(let i in buttonList){
                for(let j in this.btnText){
                    if(buttonList[i].buttonText === this.btnText[j].btnName){
                        return true
                    }else{
                        return false
                    }
                }
            }
        },
        /**
         * 验证按钮文字是否输入或选择
         * @param buttonList
         * @returns {boolean}
         */
        onButtonText(buttonList){
            let status = false
            for(let i in buttonList){
                if(buttonList[i].buttonText === ''){
                    status = true
                }
            }
            return status
        },
        /**
         * 添加按钮
         */
        onAddButton() {
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.nodeBtnList
            if(this.onButtonText(buttonList)){
                Message.warning('请输入或选择按钮文字！');
                return
            }
            if( buttonList.length>=6){
                Message.warning('您最多可以添加6个按钮！');
                return
            }
            cells[0].store.data.attribute.nodeBtnList.push({
                id:onGuid(),
                lineId:'',
                trueValue:0,
                color:'#eccf3e',
                buttonText:'',
                defaultStatus:false,
                triggerStatus:[],
                regularButton:false,
            })
            this.onButtonDisabled()
            this.onValidateData()
        },
        /**
         * 验证按钮文字是否相同
         * @param text
         * @param key
         * @returns {boolean}
         */
        onButtonTextRepeat(text,key){
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.nodeBtnList
            let status = false
            for(let i in buttonList){
                if((buttonList[i].buttonText === text && i!=key) || text==='上一步'){
                    status = true
                }
            }
            return status
        },
        /**
         * 验证按钮文字是否相同
         * @param text
         * @param key
         * @returns {boolean}
         */
        onButtonBatchRepeat(text,index,key){
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.batchList[index].btnList
            let status = false
            for(let i in buttonList){
                if(buttonList[i].buttonText === text && i!=key){
                    status = true
                }
            }
            return status
        },
        onButtonTextCheck(item,key){
            if(item.buttonText === ''){
                return
            }
            if(this.onButtonTextRepeat(item.buttonText,key)){
                this.nodeObj.attribute.nodeBtnList[key].buttonText = ''
                Message.warning('您输入的按钮文字已存在，请重新输入')
                return
            }
            if(!validateCharNumber(item.buttonText)){
                this.nodeObj.attribute.nodeBtnList[key].buttonText = ''
                Message.warning('您输入的按钮文字有误，请重新输入')
                return
            }
            if(item.buttonText.length > 5){
                this.nodeObj.attribute.nodeBtnList[key].buttonText = item.buttonText.slice(0,5)
                Message.warning('最多只能输入五个字符')
            }
        },
        onButtonBatchCheck(item,index,key){
            if(item.buttonText === ''){
                return
            }
            if(this.onButtonBatchRepeat(item.buttonText,index,key)){
                this.nodeObj.attribute.batchList[index].btnList[key].buttonText = ''
                Message.warning('您输入的按钮文字已存在，请重新输入')
                return
            }
            if(!validateCharNumber(item.buttonText)){
                this.nodeObj.attribute.batchList[index].btnList[key].buttonText = ''
                Message.warning('您输入的按钮文字有误，请重新输入')
                return
            }
            if(item.buttonText.length > 5){
                this.nodeObj.attribute.batchList[index].btnList[key].buttonText = item.buttonText.slice(0,5)
                Message.warning('最多只能输入五个字符')
            }
        },
        /**
         * 输入类型切换
         * @param input
         */
        onInputTypeSwitch(input){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.inputTypeName = input.typeName
            cells[0].store.data.attribute.inputTypeCode = input.typeCode

            if(cells[0].store.data.attribute.inputTypeCode === 'text'){
                cells[0].store.data.attribute.unitType = ''
            }
            if(cells[0].store.data.attribute.inputTypeCode === 'number' && cells[0].store.data.attribute.unitType === ''){
                this.nodeObj.attribute.unitType = '米'
            }
            this.onValidateData();
        },
        /**
         * 背景类型切换
         * @param input
         */
        onBackgroundTypeSwitch(background){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.backgroundText = background.typeName
            cells[0].store.data.attribute.backgroundCode = background.typeCode
            this.onValidateData()
        },
        /**
         * 语音播报类型切换
         * @param type
         */
        onAnnouncementsSwitch(type){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.announcementsText = type.typeName
            cells[0].store.data.attribute.announcementsType = type.typeCode
            this.onValidateData()
            this.$forceUpdate()
        },
        /**
         * 结束设置类型
         * @param type
         */
        onEndSettingsSwitch(type){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.endSettingsText = type.typeName
            cells[0].store.data.attribute.endSettingsType = type.typeCode
            this.onValidateData();
            this.$forceUpdate()
        },
        /**
         * 批量输入类型切换
         * @param input
         * @param index
         */
        onBatchInputTypeSwitch(input,index) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchInputList[index].inputTypeName = input.typeName
            cells[0].store.data.attribute.batchInputList[index].inputTypeCode = input.typeCode

            if(cells[0].store.data.attribute.batchInputList[index].inputTypeCode === 'text'){
                cells[0].store.data.attribute.batchInputList[index].unitType = ''
            }
            if(cells[0].store.data.attribute.batchInputList[index].inputTypeCode === 'number' && cells[0].store.data.attribute.batchInputList[index].unitType === ''){
                this.nodeObj.attribute.batchInputList[index].unitType = '米'
            }
        },
        /**
         * 单位切换
         * @param unit
         */
        onUnitTypeSwitch(unit) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.unitType = unit.unitType
            this.onValidateData()
        },
        /**
         * 批量输入单位切换
         * @param unit
         * @param index
         */
        onBatchUnitTypeSwitch(unit,index) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchInputList[index].unitType = unit.unitType
        },
        /**
         * 单位文字验证
         */
        onUnitTypeCheck() {
            if(this.nodeObj.attribute.unitType.length > 5){
                this.nodeObj.attribute.unitType = this.nodeObj.attribute.unitType.slice(0,5)
                Message.warning('最多只能输入五个字符')
            }
        },
        /**
         * 批量输入单位文字验证
         */
        onBatchUnitTypeCheck(index,row) {
            if(row.unitType === ''){
                this.nodeObj.attribute.batchInputList[index].unitType = ''
            }
            if(row.unitType.length > 5){
                this.nodeObj.attribute.batchInputList[index].unitType = row.unitType.slice(0,5)
                Message.warning('最多只能输入五个字符')
            }
        },
        onColor(color,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList[key].color = color.color
            this.onValidateData()
        },
        onBatchColor(color,index,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchList[index].btnList[key].color = color.color
        },
        onText(text,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList[key].buttonText = text
            this.onValidateData();
            this.$forceUpdate()
        },
        onBatchText(text,index,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchList[index].btnList[key].buttonText = text
            this.$forceUpdate()
        },
        onRemoveBtn(key){
            const cells = this.graph.getSelectedCells()
            let btnLineId = cells[0].store.data.attribute.nodeBtnList[key].lineId
            cells[0].store.data.attribute.nodeBtnList[key].lineId = ''
            let resultLineId = cells[0].store.data.attribute.nodeBtnList.some(item =>{
                if(item.lineId === btnLineId){
                    return true
                }
            })
            if(!resultLineId){
                this.graph.removeCell(btnLineId)
            }
            if(cells[0].store.data.attribute.nodeBtnList[key].lineId !==''){
                Message.warning('此按钮已被关联不可删除！')
                return
            }
            if(cells[0].store.data.attribute.nodeBtnList[key].defaultStatus){
                if(cells[0].store.data.attribute.nodeBtnList[0].buttonCode === DEFAULT_BUTTON_NAME.LOOK_IMAGE){
                    cells[0].store.data.attribute.nodeBtnList[1].defaultStatus = true
                }else{
                    cells[0].store.data.attribute.nodeBtnList[0].defaultStatus = true
                }
            }
            cells[0].store.data.attribute.nodeBtnList.splice(key,1)
            this.onValidateData()
        },
        /**
         * 选择默认按钮
         */
        onSwitchButton(item,key){
            if(item.defaultStatus === true){
                return
            }
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.nodeBtnList
            for(let i in buttonList){
                buttonList[i].defaultStatus = false
            }
            cells[0].store.data.attribute.nodeBtnList[key].defaultStatus = true
            this.onValidateData()
            this.$forceUpdate()
        },
        /**
         * 选择查看图片是否显示
         * @param item
         * @param key
         */
        onSwitchImageButton(key,value){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList[key].buttonLookImageStatus = value
            this.onValidateData()
            this.$forceUpdate()
        },
        /**
         * 点击触发条件
         * @param trigger
         * @param key
         */
        onTrigger(trigger,key) {
            if(trigger.value === TRIGGER_NAME.TRIGGER_VIDEO){
                return
            }
            const cells = this.graph.getSelectedCells()
            let statusList = cells[0].store.data.attribute.nodeBtnList[key].triggerStatus
            if(statusList.indexOf(trigger.value) > -1){
                statusList.splice(statusList.indexOf(trigger.value),1)

            }else{
                statusList.push(trigger.value)
            }
            this.onValidateData()
            this.$forceUpdate()
        },
        /**
         * 点击批量操作触发条件
         * @param trigger
         * @param index
         * @param key
         */
        onBatchTrigger(trigger,index,key) {
            if(trigger.value === TRIGGER_NAME.TRIGGER_VIDEO){
                return
            }
            const cells = this.graph.getSelectedCells()
            let statusList = cells[0].store.data.attribute.batchList[index].btnList[key].triggerStatus
            if(statusList.indexOf(trigger.value) > -1){
                statusList.splice(statusList.indexOf(trigger.value),1)
            }else{
                statusList.push(trigger.value)
            }
            this.$forceUpdate()
        },
        onChangeImageMode(mode){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.imageMode = mode
        },
        /**
         * 清空图片
         */
        onClearImage() {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.imageId = ''
            cells[0].store.data.attribute.imageUrl = ''
            this.nodeObj.attribute.imageId = ''
            this.nodeObj.attribute.imageUrl = ''
            this.$refs.inputer.value = ''
            this.onValidateData()
            this.$forceUpdate()
        },
        /**
         * 打开批量操作抽屉
         */
        onBatchConfig() {
            this.batchVisible = true
            const cells = this.graph.getSelectedCells()
            let batchList = cells[0].store.data.attribute.batchList
            if(batchList.length!==0){
                let imageArray = []
                for(let i in batchList){
                    if(batchList[i].imageId !== '' && batchList[i].imageUrl !== ''){
                        imageArray.push(batchList[i].imageId)
                    }
                }
                this.onAllImageUrl(imageArray).then()
            }
        },
        /**
         * 打开批量输入抽屉
         */
        onBatchInputConfig() {
            this.batchInputVisible = true
        },
        /**
         * 关掉抽屉验证批量操作数据
         * @param visible
         */
        onVisibleChange(visible){
            const cells = this.graph.getSelectedCells()
            let batchList = cells[0].store.data.attribute.batchList
            if(!visible){
                this.onValidateData()
                if(batchList.length!==0 && this.onBatchCheck(batchList)){
                    Message.warning('作业内容和作业标准是必填数据')
                    this.batchVisible = true
                    return
                }
                if(this.onBatchButtonCheck(batchList).status){
                    Message.warning('第'+this.onBatchButtonCheck(batchList).number+'条数据未添加按钮文字')
                    this.batchVisible = true
                    return
                }
            }
        },
        /**
         * 关掉抽屉验证批量输入数据
         * @param visible
         */
        onVisibleInputChange(visible){
            const cells = this.graph.getSelectedCells()
            let batchInputList = cells[0].store.data.attribute.batchInputList
            if(!visible){
                if(batchInputList.length!==0 && this.onBatchCheck(batchInputList)){
                    Message.warning('作业内容和作业标准是必填数据')
                    this.batchInputVisible = true
                    return
                }
                if(this.onBatchInputVoiceCheck(batchInputList).status){
                    Message.warning('第'+this.onBatchInputVoiceCheck(batchInputList).number+'条数据未添加单位')
                    this.batchInputVisible = true
                    return
                }
            }
        },
        /**
         * 查看批量操作图片
         * @param row
         */
        onLookImage(row) {
            this.batchImageSrc = row.imageUrl
            this.onImageLoad( row.imageUrl)
            this.imageObj.picUrl = row.imageUrl
            this.imageObj.solution = '查看图片'
            this.lookImageVisible = true
        },
        /**
         * 鼠标滚轮事件
         * @param e
         */
        onMousewheel(e) {
            if(e.wheelDelta > 0){
                this.onImageEnlarge()
            }else{
                this.onImageNarrow()
            }
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            this.drawing = true;
            this.currentX = e.offsetX
            this.currentY = e.offsetY
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp() {
            if (!this.drawing) { return; }
            this.drawing = false;
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if (!this.drawing) { return; }
            this.imageLeft = e.clientX - this.currentX
            this.imageTop = e.clientY - this.currentY
        },
        /**
         * 图片放大比例
         */
        onImageEnlarge() {
            if((this.imageRatio + 25 ) > 1000){
                this.imageRatio = 1000
                return
            }
            this.imageRatio+=25

            this.imageLeft -= (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop -= (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 图片缩小比例
         */
        onImageNarrow() {
            if((this.imageRatio - 25 ) < 25){
                this.imageRatio = 25
                return
            }
            this.imageRatio-=25
            this.imageLeft += (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop += (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 还原图片
         */
        onReduction() {
            this.imageRatio = 100
            this.imageLeft = (window.innerWidth - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = (window.innerHeight - this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 图片初始化
         * @param src
         */
        onImageLoad(src){
            let image = new Image();
            image.src = src
            image.onload = ()=>{
                if(image.height>window.innerHeight){
                    if(image.width>image.height){
                        this.imageWidth = image.height/image.width*window.innerHeight
                    }{
                        this.imageWidth = image.width/image.height*window.innerHeight
                    }
                    this.imageHeight = window.innerHeight
                }else{
                    this.imageWidth = image.width
                    this.imageHeight = image.height
                }
                this.imageLeft = (window.innerWidth- this.imageWidth)/2
                this.imageTop = (window.innerHeight- this.imageHeight)/2
            }
        },
        /**
         * 删除批量操作单个图片
         * @param key
         */
        onClearBatchImage(key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchList[key].imageId = ''
            cells[0].store.data.attribute.batchList[key].imageUrl = ''
        },
        /**
         * 删除批量配置
         */
        onRemoveBatch(key,name) {
            const cells = this.graph.getSelectedCells()
            if(name === 'OPERATION'){
                cells[0].store.data.attribute.batchList.splice(key,1)
            }
            if(name === 'INPUT'){
                cells[0].store.data.attribute.batchInputList.splice(key,1)
            }
        },
        /**
         * 验证批量操作数据必填项
         * @param buttonList
         * @returns {boolean}
         */
        onBatchCheck(array){
            let status = false
            for(let i in array){
                if(array[i].entryName === '' || array[i].textInfo === ''){
                    status = true
                }
            }
            return status
        },
        /**
         * 点击当前行查看AR端显示
         * @param item
         */
        onRowClick(){
            // if(item.entryName === '' || item.textInfo === ''){
            //     Message.warning('作业内容和作业标准是必填数据')
            //     return
            // }
            // if(this.onBatchButtonClickCheck(item.btnList)){
            //     Message.warning('按钮文字是必填数据')
            //     return
            // }
            // this.lookARObj = item
            // this.lookARVisible = true
        },
        /**
         * 点击当前行查看批量输入AR端显示
         * @param item
         */
        onRowBatchInputClick(item) {
            if(item.entryName === '' || item.textInfo === ''){
                Message.warning('作业内容和作业标准是必填数据')
                return
            }
            if(item.inputTypeCode === 'number' && item.unitType === ''){
                Message.warning('请输入单位')
                return
            }
            this.lookARObj = item
            this.lookARBatchInputVisible = true
        },
        /**
         * 条目名称回显
         * @param e
         * @param index
         * @param name
         */
        onEntryName(e,index,name) {
            const cells = this.graph.getSelectedCells()
            if(name === 'OPERATION'){
                cells[0].store.data.attribute.batchList[index].entryName = e.target._value
            }
            if(name === 'INPUT'){
                cells[0].store.data.attribute.batchInputList[index].entryName = e.target._value
            }
        },
        /**
         * 按钮名称回显
         * @param e
         * @param index
         * @param key
         */
        onBatchBtnName(e,index,key) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchList[index].btnList[key].buttonText = e.target._value
        },
        /**
         * 批量输入单位回显
         * @param e
         * @param index
         */
        onBatchInputUnitName(e,index,) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.batchInputList[index].unitType = e.target._value
        },
        /**
         * 文本信息回显
         * @param e
         * @param index
         * @param name
         */
        onTextInfo(e,index,name) {
            const cells = this.graph.getSelectedCells()
            if(name === 'OPERATION'){
                cells[0].store.data.attribute.batchList[index].textInfo = e.target._value
            }
            if(name === 'INPUT'){
                cells[0].store.data.attribute.batchInputList[index].textInfo = e.target._value
            }
        },
        /**
         * 添加批量输入
         */
        onAddBatchInput() {
            const cells = this.graph.getSelectedCells()
            if(cells[0].store.data.attribute.batchInputList.length!==0 && this.onBatchCheck(cells[0].store.data.attribute.batchInputList)){
                Message.warning('作业内容和作业标准是必填数据')
                return
            }
            if(this.onBatchInputVoiceCheck(cells[0].store.data.attribute.batchInputList).status){
                Message.warning('第'+this.onBatchInputVoiceCheck(cells[0].store.data.attribute.batchInputList).number+'条数据未添加单位')
                return
            }
            if(cells[0].store.data.attribute.batchList.length>=100){
                Message.warning('最多可配置100条')
                return
            }
            cells[0].store.data.attribute.batchInputList.push({
                id:onGuid(),
                entryName:'',
                textInfo:'',
                inputTypeName:'文本', //输入类型文字
                inputTypeCode:'text', //输入类型
                unitType:'', //单位
            })
        },
        /**
         * 添加批量操作
         */
        onAddBatch() {
            const cells = this.graph.getSelectedCells()
            if(cells[0].store.data.attribute.batchList.length!==0 && this.onBatchCheck(cells[0].store.data.attribute.batchList)){
                Message.warning('作业内容和作业标准是必填数据')
                return
            }
            if(this.onBatchButtonCheck(cells[0].store.data.attribute.batchList).status){
                Message.warning('第'+this.onBatchButtonCheck(cells[0].store.data.attribute.batchList).number+'条数据未添加按钮文字')
                return
            }
            if(cells[0].store.data.attribute.batchList.length>=100){
                Message.warning('最多可配置20条')
                return
            }
            cells[0].store.data.attribute.batchList.push({
                id:onGuid(),
                entryName:'',
                textInfo:'',
                imageUrl:'',
                imageId:'',
                btnList:[
                    {
                        id:onGuid(),
                        color:'#2d8cf0',
                        buttonText:'合格',
                        defaultStatus:false,
                        triggerStatus:[],
                    },
                    {
                        id:onGuid(),
                        color:'#2d8cf0',
                        buttonText:'不合格',
                        defaultStatus:false,
                        triggerStatus:[],
                    }
                ]
            })
        },
        /**
         * 编辑工作流
         */
        async onEditWorkFlow(detail) {
            try {
                this.configLoading = true
                let params = {
                    id:this.flowId,
                    flowName:this.configParams.configName,
                    videotape:this.configParams.videotapeStatus,
                    smallWindowPreview:this.configParams.smallWindowPreview,
                    quickFeedback:this.configParams.quickFeedback,
                    quickFeedbackList:this.configParams.quickFeedbackList,
                    detail:JSON.stringify(detail),
                    annexList:this.configParams.annexList
                }
                const { code,data } = await editWorkFlowService(params)
                if(code === 'SUCCESS'){
                    Message.success('保存成功！')
                    this.SET_FLOW_ID(data.id)
                    this.flowReturnObj = data
                    this.saveStatus = false
                }else if(code === 'WORK_FOLW_NAME_IS_REPEAT'){
                    Message.warning('工作流名称重复！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 获取工作流信息
         */
        async onWorkFlowInfo() {
            try {
                this.configLoading = true
                let params = {
                    id:this.flowId,
                }
                const { code,data } = await getWorkFlowInfoService(params)
                if(code === 'SUCCESS'){
                    this.configParams.videotapeStatus = data.videotape
                    this.configParams.smallWindowPreview = data.smallWindowPreview
                    this.configParams.configName = data.flowName
                    this.configParams.quickFeedback = data.quickFeedback
                    this.configParams.quickFeedbackList = data.quickFeedbackList
                    this.graph.fromJSON(JSON.parse(data.detail))
                    this.configParams.flowName = data.flowName
                    this.configParams.annexList = data.annexList
                    this.flowReturnObj = data

                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 获取工作流节点
         */
        async onWorkFlowNodesService() {
            try {
                this.configLoading = true
                let params = {}
                const { code,data } = await getWorkFlowNodesService(params)
                if(code === 'SUCCESS'){
                    this.nodeArray = data
                    for(let i in this.configList){
                        for(let j in this.configList[i].childNode){
                            if(!this.nodeArray.includes(this.configList[i].childNode[j].nodeCode)){
                                this.configList[i].childNode.splice(j,1)
                            }
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 工作流更新发布状态
         */
        async onUpdateWorkFlowStatus(status) {
            try {
                let params = {
                    id:this.flowReturnObj.id,
                    status:status
                }
                const { code } = await updateWorkFlowStatusService(params)
                if(code === 'SUCCESS'){
                    if(status === 1 ){
                        Modal.confirm({
                            title: '发布成功',
                            content: '<p>作业流程已发布，是否前去创建作业任务？</p>',
                            onOk: () => {
                                this.SET_TASK_EDIT('')
                                this.$router.push('/workflow/edit/task')
                            },
                        });
                    }
                    if(status === 0 ){
                        Message.success('撤回成功！')
                    }
                    this.flowReturnObj.status = status
                    this.flowReturnObj.useNum = 0
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 编辑任务
         */
        async onEditTaskList(status) {
            try {
                this.editLoading = true
                let params = {
                    id:'',
                    tasksName:this.configParams.tasksName,
                    flowId:this.flowReturnObj.id,
                    status:status,
                    tasksType:this.configParams.tasksType
                }
                const { code } = await editTaskListService(params)
                if(code === 'SUCCESS'){
                    this.$router.push('/workflow/task')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id) {
            try {
                this.systemLoading = true
                let params = {
                    resourceName:id,
                }
                const {code,data} = await queryFilesInfoService(params);
                if(code === 'SUCCESS'){
                    const cells = this.graph.getSelectedCells()
                    cells[0].store.data.attribute.imageUrl = data.url
                    this.$forceUpdate()
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
                this.systemLoading = false
            }catch (error) {
                this.systemLoading = false
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onAllImageUrl(array) {
            try {
                this.batchLoading = true
                let params = array
                const {code,data} = await getAllImageUrlService(params);
                const cells = this.graph.getSelectedCells()
                let batchList = cells[0].store.data.attribute.batchList
                if(code === 'SUCCESS'){
                    for(let i in data){
                        for(let j in batchList){
                            if(data[i].id === batchList[j].imageId){
                                batchList[j].imageUrl = data[i].url
                            }
                        }
                    }
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
                this.batchLoading = false
            }catch (error) {
                this.batchLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default ProcessConfig
